import { PaymentsHistory, UIPosition, UIStrategy, UITrade } from '@derivadex/types';
import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/slices';

const selectOpenOrders = (state: AppState) => state.strategy.openOrders;
export const getOpenOrders = createSelector([selectOpenOrders], (selectOpenOrders) => {
    return selectOpenOrders;
});

const selectedStrategy = (state: AppState) => {
    if (!state.strategy.activeStrategy) return undefined;
    return state.strategy.strategies[state.strategy.activeStrategy];
};
export const getSelectedStrategy = createSelector([selectedStrategy], (selectedStrategy) => {
    return selectedStrategy;
});

export function getSelectedStrategyId(state: AppState): string | undefined {
    if (!state.strategy.activeStrategy) return undefined;
    return state.strategy.activeStrategy;
}

export function getSelectedStrategyMargin(state: AppState): string | undefined {
    if (!state.strategy.activeStrategy) return undefined;
    return state.strategy.strategies[state.strategy.activeStrategy].frontendMargin.toString();
}

const selectedStrategyValue = (state: AppState) => {
    if (!state.strategy.activeStrategy) return undefined;
    return state.strategy.strategies[state.strategy.activeStrategy].strategyValue.toString();
};
export const getSelectedStrategyValue = createSelector([selectedStrategyValue], (selectedStrategyValue) => {
    return selectedStrategyValue;
});

const selectedStrategyMf = (state: AppState) => {
    if (!state.strategy.activeStrategy) return undefined;
    return state.strategy.strategies[state.strategy.activeStrategy].mf.toString();
};
export const getSelectedStrategyMf = createSelector([selectedStrategyMf], (selectedStrategyMf) => {
    return selectedStrategyMf;
});

const selectedStrategyMmr = (state: AppState) => {
    if (!state.strategy.activeStrategy) return undefined;
    return state.strategy.strategies[state.strategy.activeStrategy].mmr.toString();
};
export const getSelectedStrategyMmr = createSelector([selectedStrategyMmr], (selectedStrategyMmr) => {
    return selectedStrategyMmr;
});

const selectedStrategyLeverage = (state: AppState) => {
    if (!state.strategy.activeStrategy) return undefined;
    return state.strategy.strategies[state.strategy.activeStrategy].leverage.toString();
};
export const getSelectedStrategyLeverage = createSelector([selectedStrategyLeverage], (selectedStrategyLeverage) => {
    return selectedStrategyLeverage;
});

export function getStrategies(state: AppState): { [key: string]: UIStrategy } {
    return state.strategy.strategies;
}

const selectStrategies = (state: AppState) => state.strategy.strategies;
export const getStrategiesAsList = createSelector([selectStrategies], (selectStrategies) => {
    return Object.values(selectStrategies);
});

export function getPositions(state: AppState): { [symbol: string]: UIPosition } {
    return state.strategy.positions;
}

const selectPositions = (state: AppState) => state.strategy.positions;
export const getPositionsAsList = createSelector([selectPositions], (selectPositions) => {
    return Object.values(selectPositions);
});

export function getSelectedMarketPosition(state: AppState, symbol: string): UIPosition | undefined {
    return state.strategy.positions[symbol];
}

export function getTradesHistoryDirtyFlag(state: AppState): boolean {
    return state.strategy.isDirtyFlagTradesHistory;
}

export function getPaymentsHistoryDirtyFlag(state: AppState): boolean {
    return state.strategy.isDirtyFlagPaymentsHistory;
}

export function getTradesHistory(state: AppState): UITrade[] {
    return state.strategy.tradesHistory;
}

export function getPaymentsHistory(state: AppState): PaymentsHistory[] {
    return state.strategy.paymentsHistory;
}
