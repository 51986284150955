import { getFrontendLogger } from '@derivadex/utils';

type Node0HealthyStatus = {
    operatorRunningState: string;
};

export const parseNode0HealthyResponse = async (response: Response): Promise<Node0HealthyStatus> => {
    const json: any = await response.json();
    return { operatorRunningState: json.operatorRunningState };
};

export async function fetchFrontendApiStatus(restUrl: string): Promise<boolean> {
    try {
        let response: any = undefined;
        response = await fetch(`${restUrl}/status`);
        const parsedResponse = await parseNode0HealthyResponse(response!);
        if (parsedResponse.operatorRunningState !== 'Master') {
            return false;
        }
        return true;
    } catch (err) {
        getFrontendLogger().logError('System malfunction: Frontend Api Status Endpoint unreachable.');
        return false;
    }
}
