import { ActionType, UIToastActionID, UIToastStatus } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { initialRuntimeConfig } from 'config/runtimeConfig';
import i18n from 'i18next';
import { getLatestStrategyDeposit } from 'store/transactions/selectors';
import { WITHDRAW_DDX_STATE_UPDATE_TRIGGER, WITHDRAW_STATE_UPDATE_TRIGGER } from 'store/web3/slice';
import { all, call, delay, fork, putResolve, select, takeLatest } from 'typed-redux-saga/macro';
import {
    DEPOSIT_DIALOG_BALANCE_UPDATE_INTERVAL,
    FRONTEND_API_STATUS_UPDATE_INTERVAL,
    WITHDRAW_DIALOG_UPDATE_INTERVAL,
} from 'utils/constants';

import { isDepositCollateralsDialogActive, isWithdrawDdxDialogActive, isWithdrawTokensDialogActive } from './selectors';
import {
    ADD_TOAST_MESSAGE,
    DepositDdxUIState,
    DepositUsdcUIState,
    SET_DEPOSIT_DDX_UI_STATE,
    SET_DEPOSIT_USDC_UI_STATE,
    SET_IS_NODE0_HEALTHY,
    TOGGLE_DEPOSIT_COLLATERALS_DIALOG,
    TOGGLE_WITHDRAW_DDX_DIALOG,
    TOGGLE_WITHDRAW_TOKENS_DIALOG,
} from './slice';
import { fetchFrontendApiStatus } from './utils';

export function* handleToggleDepositDialog() {
    let stop = false;
    try {
        while (!stop) {
            const isActive = yield* select(isDepositCollateralsDialogActive);
            if (!isActive) {
                stop = true;
                break;
            }
            yield* putResolve({ type: ActionType.TOKEN_BALANCE_JOB });
            yield* delay(DEPOSIT_DIALOG_BALANCE_UPDATE_INTERVAL);
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

// Added to be able to test the behavior of having the deposit dialog active
// This logic will exist on a more specific ui saga later
function* watchToggleDepositDialog() {
    yield* takeLatest(TOGGLE_DEPOSIT_COLLATERALS_DIALOG, handleToggleDepositDialog);
}

function* handleDepositUsdcState(action: ReturnType<typeof SET_DEPOSIT_USDC_UI_STATE>) {
    try {
        if (action.payload === DepositUsdcUIState.CONFIRMED) {
            const depositUsdc = yield* select(getLatestStrategyDeposit);
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Successful,
                    title: i18n.t('transactionSuccessful'),
                    description: i18n.t('confirmed'),
                    actionID: UIToastActionID.DepositCollateral,
                    txHash: depositUsdc?.txHash,
                    depositTx: depositUsdc,
                }),
            );
            yield* putResolve(SET_DEPOSIT_USDC_UI_STATE(DepositUsdcUIState.NONE));
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

function* watchDepositUsdcState() {
    yield* takeLatest(SET_DEPOSIT_USDC_UI_STATE, handleDepositUsdcState);
}

function* handleDepositDdxState(action: ReturnType<typeof SET_DEPOSIT_DDX_UI_STATE>) {
    try {
        if (action.payload === DepositDdxUIState.CONFIRMED) {
            const depositUsdc = yield* select(getLatestStrategyDeposit);
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Successful,
                    title: i18n.t('transactionSuccessful'),
                    description: i18n.t('confirmed'),
                    actionID: UIToastActionID.DepositDDX,
                    txHash: depositUsdc?.txHash,
                    depositTx: depositUsdc,
                }),
            );
            yield* putResolve(SET_DEPOSIT_DDX_UI_STATE(DepositDdxUIState.NONE));
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function* handleIsNode0Healthy() {
    let stop = false;
    while (stop === false) {
        const healthUrl = `${document.location.protocol}//${document.location.host}/v2/rest`;
        const restUrl = initialRuntimeConfig.REST_API_URL || healthUrl;
        const status = yield* call(fetchFrontendApiStatus, restUrl);
        if (status === false) {
            getFrontendLogger().log(`Got operator node status ${status}`);
            throw new Error('System malfunction: Operator node leader possibly down.');
        }
        yield* putResolve(SET_IS_NODE0_HEALTHY(status));
        stop = !status;
        if (stop === false) yield* delay(FRONTEND_API_STATUS_UPDATE_INTERVAL);
    }
}

function* watchDepositDdxState() {
    yield* takeLatest(SET_DEPOSIT_DDX_UI_STATE, handleDepositDdxState);
}

export function* handleToggleWithdrawDialog() {
    let stop = false;
    try {
        while (!stop) {
            const isActive = yield* select(isWithdrawTokensDialogActive);
            if (!isActive) {
                stop = true;
                break;
            }
            yield* putResolve(WITHDRAW_STATE_UPDATE_TRIGGER());
            yield* delay(WITHDRAW_DIALOG_UPDATE_INTERVAL);
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

// Added to be able to test the behavior of having the deposit dialog active
// This logic will exist on a more specific ui saga later
function* watchToggleWithdrawDialog() {
    yield* takeLatest(TOGGLE_WITHDRAW_TOKENS_DIALOG, handleToggleWithdrawDialog);
}

export function* handleToggleWithdrawDDXDialog() {
    let stop = false;
    try {
        while (!stop) {
            const isActive = yield* select(isWithdrawDdxDialogActive);
            if (!isActive) {
                stop = true;
                break;
            }
            yield* putResolve(WITHDRAW_DDX_STATE_UPDATE_TRIGGER());
            yield* delay(WITHDRAW_DIALOG_UPDATE_INTERVAL);
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

// Added to be able to test the behavior of having the deposit dialog active
// This logic will exist on a more specific ui saga later
function* watchToggleWithdrawDDXDialog() {
    yield* takeLatest(TOGGLE_WITHDRAW_DDX_DIALOG, handleToggleWithdrawDDXDialog);
}

export function* uiSaga() {
    yield* all([
        fork(watchToggleDepositDialog),
        fork(watchDepositUsdcState),
        fork(watchDepositDdxState),
        fork(watchToggleWithdrawDialog),
        fork(watchToggleWithdrawDDXDialog),
    ]);
}
