import { BigNumber } from '@0x/utils';
import { UIDowntimeCode, UIDowntimeState, UIDowntimeSubcode, UIToast } from '@derivadex/types';
import { AppState } from 'store/slices';
import { s } from 'vitest/dist/env-afee91f0';

import {
    CancelUIState,
    ClosePositionUIState,
    DepositDdxUIState,
    DepositUsdcUIState,
    OneClickTradingOptInUIData,
    PlaceOrderUIState,
    ProfileIntentUIState,
    SubmitCheckpointUIState,
    ToggleDdxUIState,
    ToggleUsdcUIState,
    UIViewState,
    WithdrawDdxUIState,
    WithdrawIntentDdxUIState,
    WithdrawIntentUsdcUIState,
    WithdrawUsdcUIState,
} from './slice';

export function isDepositCollateralsDialogActive(state: AppState): boolean {
    return state.ui.isDepositDialogActive;
}

export function isWithdrawTokensDialogActive(state: AppState): boolean {
    return state.ui.isWithdrawTokensDialogActive;
}

export function isWithdrawDdxDialogActive(state: AppState): boolean {
    return state.ui.isWithdrawDdxDialogActive;
}

export function isWalletDialogActive(state: AppState): boolean {
    return state.ui.isWalletDialogActive;
}

export function isMarketsDialogActive(state: AppState): boolean {
    return state.ui.isMarketsDialogActive;
}

export function isStrategiesDialogActive(state: AppState): boolean {
    return state.ui.isStrategiesDialogActive;
}

export function getCancelUIState(state: AppState): CancelUIState {
    return state.ui.cancel;
}

export function getSubmitCheckpointUIState(state: AppState): SubmitCheckpointUIState {
    return state.ui.checkpoint;
}

export function getClosePositionUIState(state: AppState): ClosePositionUIState {
    return state.ui.closePosition;
}

export function getOneClickTradingOptInUIData(state: AppState): OneClickTradingOptInUIData {
    return state.ui.oneClickTradingOptInData;
}

export function getDepositUsdcUIState(state: AppState): DepositUsdcUIState {
    return state.ui.depositUsdc;
}

export function getDepositDdxUIState(state: AppState): DepositDdxUIState {
    return state.ui.depositDdx;
}

export function getWithdrawIntentUsdcUIState(state: AppState): WithdrawIntentUsdcUIState {
    return state.ui.withdrawIntentUsdc;
}

export function getWithdrawUsdcUIState(state: AppState): WithdrawUsdcUIState {
    return state.ui.withdrawUsdc;
}

export function getWithdrawIntentDdxUIState(state: AppState): WithdrawIntentDdxUIState {
    return state.ui.withdrawIntentDdx;
}

export function getWithdrawDdxUIState(state: AppState): WithdrawDdxUIState {
    return state.ui.withdrawDdx;
}

export function getToggleUsdcUIState(state: AppState): ToggleUsdcUIState {
    return state.ui.toggleUsdc;
}

export function getToggleDdxUIState(state: AppState): ToggleDdxUIState {
    return state.ui.toggleDdx;
}

export function getProfileIntentUIState(state: AppState): ProfileIntentUIState {
    return state.ui.profileIntent;
}

export function getPlaceOrderUIState(state: AppState): PlaceOrderUIState {
    return state.ui.placeOrder;
}

export function getActiveToast(state: AppState): UIToast | undefined {
    return state.ui.toasts.activeToast;
}

export function getToastQueue(state: AppState): UIToast[] {
    return state.ui.toasts.toastQueue;
}

export function getOrderbookPriceSelected(state: AppState): BigNumber | undefined {
    return state.ui.orderbookPriceSelected;
}

export function getDowntime(state: AppState): boolean {
    return state.ui.downtime;
}

export function getDowntimeMessage(state: AppState): UIDowntimeState {
    return state.ui.downtimeMessage;
}

export function getDowntimeCode(state: AppState): UIDowntimeCode {
    return state.ui.downtimeCode;
}

export function getDowntimeSubcode(state: AppState): UIDowntimeSubcode | undefined {
    return state.ui.downtimeSubcode;
}

export function getIsConsentDialogActive(state: AppState): boolean {
    return state.ui.isConsentDialogActive;
}

export function getCurrentView(state: AppState): UIViewState {
    return state.ui.view;
}

export function isNode0Healthy(state: AppState): boolean {
    return state.ui.isNode0Healthy;
}
