import { EstimateFundingRate, Fill, Market, MarkPrice, OpenInterest, Ticker } from '@derivadex/types';
import { createSelector } from '@reduxjs/toolkit';
import { BigNumber } from 'bignumber.js';
import { AppState } from 'store/slices';

export function getSelectedMarket(state: AppState): Market | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    return state.market.markets[state.market.selectedMarket];
}

export function getSelectedMarketTickSize(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    const market = state.market.markets[state.market.selectedMarket];
    if (market === undefined) return undefined;
    return market.tickSize;
}

export function getRecentFills(state: AppState): Fill[] {
    return state.market.recentFills;
}

export function getMarkets(state: AppState): { [id: string]: Market } {
    return state.market.markets;
}

const selectMarkets = (state: AppState) => state.market.markets;
export const getMarketsAsList = createSelector([selectMarkets], (selectMarkets) => {
    return Object.values(selectMarkets);
});

export function getMarket(state: AppState, symbol: string): Market | undefined {
    return state.market.markets[symbol];
}

const selectBook = (state: AppState) => state.market.book;
export const getOrderBookSnapshot = createSelector([selectBook], (selectBook) => {
    const book = selectBook;
    return book;
});

export function getTickerForMarket(state: AppState, symbol: string): Ticker | undefined {
    return state.market.tickers[symbol];
}

export function getTickers(state: AppState) {
    return state.market.tickers;
}

export function getOpenInterest(state: AppState, symbol: string): OpenInterest | undefined {
    return state.market.openInterestData[symbol];
}

export function getOpenInterests(state: AppState) {
    return state.market.openInterestData;
}

export function getSelectedMarketPrice(state: AppState, symbol: string): MarkPrice | undefined {
    return state.market.markPrices[symbol];
}

export function getMarkPrices(state: AppState) {
    return state.market.markPrices;
}

export function getFundingRateForMarket(state: AppState, symbol: string): EstimateFundingRate | undefined {
    return state.market.fundingRates[symbol];
}

export function getSelectedMarketMarkPrice(state: AppState): MarkPrice | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    return state.market.markPrices[state.market.selectedMarket];
}

export function getSelectedMarketFundingRate(state: AppState): EstimateFundingRate | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    return state.market.fundingRates[state.market.selectedMarket];
}

export function getFundingRates(state: AppState): { [symbol: string]: EstimateFundingRate } {
    return state.market.fundingRates;
}

export function getPreviousClose(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) {
        return undefined;
    }
    return state.market.previousClose[state.market.selectedMarket];
}

export function getSelectedMarketTicker(state: AppState): Ticker | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket];
}

export function getSelectedMarketTickerClose(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].close;
}

export function getSelectedMarketTickerOpen(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].open;
}

export function getSelectedMarketTickerVolume(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].volume;
}

export function getSelectedMarketTickerLow(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].low;
}

export function getSelectedMarketTickerHigh(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].high;
}

const selectPrices = (state: AppState) => state.market.markPrices;
export const getPricesAsList = createSelector([selectPrices], (selectPrices) => {
    return Object.values(selectPrices);
});

export function getPrices(state: AppState): { [symbol: string]: MarkPrice } {
    return state.market.markPrices;
}
